exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2eZi_ {\n  margin-top: 0 !important;\n  padding-top: 0 !important;\n}\n._2eZi_ ._3DsgW {\n  display: inline-block;\n  margin-left: 10px;\n  cursor: pointer;\n  opacity: 0.5;\n  -webkit-transition: opacity 0.3s;\n  -o-transition: opacity 0.3s;\n  transition: opacity 0.3s;\n}\n._2eZi_ ._3DsgW:active,\n._2eZi_ ._3DsgW:focus,\n._2eZi_ ._3DsgW:hover {\n  opacity: 1;\n}\n._3hnsN {\n  cursor: pointer;\n}\n._3hnsN:active > ._3DsgW,\n._3hnsN:focus > ._3DsgW,\n._3hnsN:hover > ._3DsgW {\n  opacity: 1;\n}\n._3hnsN ._1kjg1 {\n  color: #BB0000 !important;\n}\n", ""]);

// Exports
exports.locals = {
	"list": "_2eZi_",
	"btnCopy": "_3DsgW",
	"cursor": "_3hnsN",
	"danger": "_1kjg1"
};