import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import loc from 'Components/languages';
import { convertFloatToPrice, extractDateFromDate } from 'Helpers/helpers';
import styles from './StockListItem.less';

class StockListItem extends PureComponent {
	constructor() {
		super();
		this.onClickHandler = this.onClickHandler.bind(this);
	}

	onClickHandler() {
		const { item, onSelect } = this.props;
		onSelect && onSelect(item);
	}

	render() {
		const { item, lotNo, appLanguage } = this.props;
		const { onSelect } = this.props;
		if (!item) {
			return null;
		}
		return (
			<li className={ classnames(styles.list, onSelect && styles.cursor) }>
				<div className="uk-card uk-card-body uk-card-small uk-position-relative">
					<div className="uk-flex uk-flex-between uk-child-width-1-1 uk-h5 uk-text-uppercase uk-margin-remove-bottom" onClick={ this.onClickHandler }>
						<div className="uk-text-primary">
							{ appLanguage === 'en' ? item.item_name_en : item.item_name_tc }
						</div>
					</div>
					<div className="uk-flex uk-flex-between uk-text-small uk-text-muted uk-text-capitalize">
						<div className="uk-width-1-4">
							<CopyToClipboard text={ item.item_code }>
								<div className={ styles.cursor }>
									{ loc.itemCode }: { item.item_code }
									<div className={ styles.btnCopy }>
										<FontAwesomeIcon icon={ faCopy } />
									</div>
								</div>
							</CopyToClipboard>
						</div>
						<div className="uk-width-1-4" onClick={ this.onClickHandler }>
							{ loc.brandName }: { appLanguage === 'en' ? item.brand.name_en : item.brand.name_tc }
						</div>
						<div className="uk-width-1-4" onClick={ this.onClickHandler }>
							{ loc.category }: { appLanguage === 'en' ? item.category.name_en : item.category.name_tc }
						</div>
						<div className="uk-width-1-4" onClick={ this.onClickHandler }>
							{ loc.unitPrice }: { convertFloatToPrice(item.unit_price) }
						</div>
					</div>
					{
						lotNo ? (
							<div className="uk-flex uk-flex-between uk-text-small uk-text-muted uk-text-capitalize">
								<div className="uk-width-1-4">
									<CopyToClipboard text={ lotNo.lot_no }>
										<div className={ styles.cursor }>
											{ loc.lotNo }: { lotNo.lot_no }
											<div className={ styles.btnCopy }>
												<FontAwesomeIcon icon={ faCopy } />
											</div>
										</div>
									</CopyToClipboard>
								</div>
								<div className="uk-width-1-4" onClick={ this.onClickHandler }>
									{ loc.qty }: { lotNo.quantity }
								</div>
								<div className="uk-width-1-4" onClick={ this.onClickHandler }>
									{ loc.commitQty }: { lotNo.commit_quantity }
								</div>
								<div className="uk-width-1-4" onClick={ this.onClickHandler }>
									{ loc.shop }: { lotNo.warehouse_desc }
								</div>
							</div>
						) : (
							<div className="uk-flex uk-flex-between uk-text-small uk-text-muted uk-text-capitalize" onClick={ this.onClickHandler }>
								<div className="uk-width-1-4">
									{ loc.totalQty }: { item.stock_check_total_qty }
								</div>
								<div className="uk-width-1-4">
									{ loc.commitQty }: { item.total_commit_qty }
								</div>
								<div className="uk-width-1-4" />
								<div className="uk-width-1-4" />
							</div>
						)
					}
					{
						lotNo && lotNo.expiry_date && (
							<div className={ classnames('uk-text-small uk-text-capitalize', lotNo.is_expired ? styles.danger : 'uk-text-muted') }>
								{ loc.expiryDate }: { lotNo.expiry_date ? extractDateFromDate(lotNo.expiry_date) : '' }
							</div>
						)
					}
				</div>
			</li>
		);
	}
}

StockListItem.propTypes = {
	index: PropTypes.number.isRequired,
	item: PropTypes.object,
	lotNo: PropTypes.object,
	onSelect: PropTypes.func,
};

export default withRouter(connect(
	(state) => ({
		appLanguage: state.appLanguage,
	})
)(StockListItem));